import './index.css'

export const Button = ({ children, href, ...rest }) => {

    if (!href) {
        return (
            <button {...rest}>{children}</button>
        )
    } else {
        return (
            <a href={href}>
                <button {...rest}>{children}</button>
            </a>
        )
    }
}
