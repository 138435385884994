import { Label, Button } from '../../atoms';
import './index.css';


export const Form = ({ name, onSubmit: submit, buttonLabel, children, error }) => (
    <form className="form" name={name}>
        {children}
        <Button className="dark" onClick={(e) => { e.preventDefault();submit(); }}>{buttonLabel }</Button>
        {error && (
            <div className="form-error">
                <Label>{error}</Label>
            </div>
        )}
    </form>
)
