import { ContribCard } from '../../atoms'
import './index.css'

import { SubPageHeader, SubPageFooter } from '../../organisms';

export const ContributorsPage = () => {
    return (
        <>
            <SubPageHeader title="Contributeurs"/>
            <section className="subpage-section section-contributors first">
                <div className="content">
                    <h2>Contributeurs</h2>
                    <h3>Aaaaaa</h3>
                    <ContribCard name="Nicolas ROUSSE" />
                    <h3>Bbbbbb</h3>
                </div>
            </section>
            <SubPageFooter />
        </>
    );
}
