import { Illustration, Button } from '../../atoms'
import './index.css'

export const SectionApp = () => {
    const LESSONSHARING_APP_URL = 'https://app.lessonsharing.fr/';

    return (
        <section className="section app" id={"Application"}>
            <div className="content">
                <div className="left">
                    <h2>Notre application</h2>
                    <p>L'application LessonSharing a pour but le partage des fichiers en toute simplicité.</p>
                    <p>L'accès à l'application LessonSharing est entièrement gratuit, incluant des limitations d'utilisation.</p>
                    <p>Une offre Premium est disponible pour les utilisateurs souhaitant exploiter toutes les fonctionnalités de l'application.</p>
                    <p>Utilisée par des écoles, particuliers, petites et grandes entreprises, l'application est adaptée à de multiples contextes d'utilisation. Elle permet par exemple, aux enseignants de communiquer avec leurs classes, ou encore, elle permet à des collaborateurs l'échange de données et documents en toute sécurité !</p>
                </div>
                <div className="unit">
                    <Illustration src="/apps.svg" alt="application"></Illustration>
                    <p>Créez-vous un compte, explorez les fonctionnalités et partagez vos documents !</p>
                    <br />
					<a href={LESSONSHARING_APP_URL} target='_blank' style={{textDecoration:'none'}}>
						<Button className="dark">Découvrir l'application</Button>
					</a>
                </div>
            </div>
        </section>
    )
}
