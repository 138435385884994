import { useState } from 'react';

import { TextInput, TextArea } from '../../atoms'
import { Form, FormInput } from '../../molecules';

import './index.css'

export const SectionForm = () => {

    const [form, setForm] = useState({
        names: { value: '', message: '' },
        email: { value: '', message: '' },
        text: { value: '', message: '' },
    })

    const handleSubmit = () => {
        let isValid = true;

        /* mandatory fields validation */
        const state = { ...form }
        Object.keys(state).forEach(key => {
            if (!state[key].value) {
                isValid = false;
                state[key].message = "Cette valeur est requise."
            } else {
                state[key].message = ""
            }
        })
        setForm(state);

        /** email validation */
        const emailRegex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
        if (form.email.value) {
            let emailMsg = ''
            if (!form.email.value.match(emailRegex)) {
                isValid = false;
                emailMsg = `Format de l'email invalide.`
            }
            setForm({ ...form, email: { ...form.email, message: emailMsg } })
        }


        if (isValid) {
            alert("Formulaire validé");
            /**
             * To implement backend call
             */
        }
    }

    return (
        <section className="section contact" id={"Contact"}>
            <div className="content">
                <div className="left">
                    <h2>Nous contacter</h2>
                    <p>Notre expertise au service de vos demandes, vos besoins, vos questions</p>
                </div>
                <Form buttonLabel="Envoyer" onSubmit={handleSubmit}>
                    <FormInput label="Prénom Nom">
                        <TextInput required
                            onChange={(e) => setForm({ ...form, names: { ...form.names, value: e.target.value } })}
                            value={form.names.value}
                            placeholder="Jean-Paul Dubois"
                        />
                        {form.names.message && <div className="error-message">{form.names.message}</div>}
                    </FormInput>
                    <FormInput label="Email">
                        <TextInput required
                            placeholder="jean-paul.dubois@domain.com"
                            onChange={(e) => setForm({ ...form, email: { ...form.email, value: e.target.value } })}
                            value={form.email.value}
                        />
                        {form.email.message && <div className="error-message">{form.email.message}</div>}
                    </FormInput>
                    <FormInput label="Votre message (nous répondons généralement en moins d'une semaine)">
                        <TextArea required
                            placeholder="Demandez nous la lune !"
                            rows={10}
                            cols={5}
                            minLength={5}
                            maxLength={200}
                            onChange={(e) => setForm({ ...form, text: { ...form.text, value: e.target.value } })}
                            value={form.text.value}
                        />
                        {form.text.message && <div className="error-message">{form.text.message}</div>}
                    </FormInput>
                </Form>
            </div>
        </section>
    )
}
