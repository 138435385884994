import './index.css'

export const SubPageFooter = () => {
    const LOCAL_DATE = new Date();

    return (
        <footer className="subpage-section subpage-footer">
            {/* Copyright */}
            <p>&copy; {LOCAL_DATE.getFullYear()} LessonSharing</p>
        </footer>
    )
}
