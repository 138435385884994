import { Link } from 'react-router-dom';
import { Illustration } from  '../../atoms'
import './index.css'

export const SectionFooter = () => {
    const LOCAL_DATE        = new Date(),
          INTERNAL_LEGAL_NOTICE_URL = '/legal-notices';

    const LS_SOCNET_FACEBOOK = 'https://www.facebook.com/LessonSharing/',
          LS_SOCNET_LINKEDIN = 'https://fr.linkedin.com/company/lessonsharing-sas/',
          LS_SOCNET_TWITTER  = 'https://twitter.com/lessonsharing';

    return (
        <footer className="main-footer">
            {/* Social Networks links */}
            <div>
                {/* LinkedIn */}
                <a href={LS_SOCNET_LINKEDIN} target='_blank' rel='noreferrer'>
                    <Illustration src='/linkedin.svg' alt='LinkedIn'></Illustration>
                </a>
                {/* Facebook */}
                <a href={LS_SOCNET_FACEBOOK} target='_blank' rel='noreferrer'>
                    <Illustration src='/facebook.svg' alt='Facebook'></Illustration>
                </a>
                {/* Twitter */}
                <a href={LS_SOCNET_TWITTER}  target='_blank' rel='noreferrer'>
                    <Illustration src='/twitter.svg' alt='Twitter'></Illustration>
                </a>
            </div>

            {/* Copyright */}
            <p style={{marginTop:'20px'}}>&copy; {LOCAL_DATE.getFullYear()} LessonSharing</p>

            {/* Link to legal notices */}
            <Link to={INTERNAL_LEGAL_NOTICE_URL} style={{color:'inherit'}} target='_blank'>Mentions légales</Link>

            {/* Made with <3 */}
            <p className='madewithlove'>
                Made with
                <Illustration src='/heart.svg' alt='Heart'></Illustration>
                {/*by <Link to='/contributors' style={{color:'inherit'}} target='_blank'>contributors</Link>*/}
                by contributors
            </p>
        </footer>
    )
}
