import { Illustration } from '../../atoms'
import './index.css'

import { SubPageHeader, SubPageFooter } from '../../organisms';

export const LegalNoticesPage = () => {
    const LESSONSHARING_URL = 'https://www.lessonsharing.fr/',
          GOOGLE_MAPS_IFRAME_URL = 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2637.6357942251843!2d2.50242565163283!3d48.616814379162705!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47e5e1ee91c76423%3A0x488793c6ce6193b!2sLessonSharing%20France!5e0!3m2!1sfr!2sfr!4v1643541055109!5m2!1sfr!2sfr';

    return (
        <>
            <SubPageHeader title="Mentions légales" />
            <section className="subpage-section section-legalnotices first">
                <div className="content">
                    <h2>Mentions légales</h2>
                    <p>Le site Internet <a href={LESSONSHARING_URL}>www.lessonsharing.fr</a> est édité par la société LESSONSHARING SARL, situé 76 RUE DU TROU GRILLON, 91280 SAINT-PIERRE-DU-PERRAY en FRANCE, dont le numéro SIRET est le : 841 178 791 00023.</p>
                    <p>Pour contacter LESSONSHARING SARL :</p>
                    <address>
                        Par e-mail : <a href="mailto:contact@lessonsharing.fr">contact@lessonsharing.fr</a><br />
                        Par courrier postal :<br />
                        LESSONSHARING SARL<br />
                        76 RUE DU TROU GRILLON<br />
                        91280 SAINT-PIERRE-DU-PERRAY<br />
                        FRANCE
                    </address>

                    <p>Nous situer sur Google Maps :</p>
                    <iframe
                        title="LessonSharing on Google Maps"
                        allowFullScreen={false}
                        loading="lazy"
                        src={GOOGLE_MAPS_IFRAME_URL}>
                    </iframe>

                    <h3>Responsable du site Web</h3>
                    <p>Le directeur de la publication et du développement est Monsieur Gaëtan MAIURI &lt;<a href="mailto:maiuri.gaetan@lessonsharing.fr">maiuri.gaetan@lessonsharing.fr</a>&gt;.</p>

                    <h3>Contact par voie électronique</h3>
                    <p>Vous pouvez adresser par voie électronique vos demandes et réclamations, en vous adressant au service concerné :</p>
                    <ul style={{marginTop:0}}>
                        <li>Adresse générale, votre e-mail sera transféré dans le service approprié : <a href="mailto:contact@lessonsharing.fr">contact@lessonsharing.fr</a></li>
                        <li>Address directe vers le service d'hébergement (serveurs, bases de données, etc.) : <a href="mailto:hostmaster@lessonsharing.fr">hostmaster@lessonsharing.fr</a></li>
                        <li>Adresse directe vers le service de développement Web : <a href="mailto:webmaster@lessonsharing.fr">webmaster@lessonsharing.fr</a></li>
                        <li>Adresse directe associée aux développeurs : <a href="mailto:developers@lessonsharing.fr">developers@lessonsharing.fr</a></li>
                        <li>Adresse directe vers le service des relations clientelles : <a href="mailto:customer@lessonsharing.fr">customer@lessonsharing.fr</a></li>
                        <li>Adresse directe vers le service d'aide : <a href="mailto:help@lessonsharing.fr">help@lessonsharing.fr</a></li>
                    </ul>

                    <h3>Hébergeur</h3>
                    <p>Le site Internet <a href={LESSONSHARING_URL}>www.lessonsharing.fr</a> est hébergé par la société LESSONSHARING SARL.</p>
                    <p>Toute réclamation devra être adressée à :</p>
                    <address>
                        LESSONSHARING SARL<br />
                        SERVICE HEBERGEMENT<br />
                        76 RUE DU TROU GRILLON<br />
                        91280 SAINT-PIERRE-DU-PERRAY<br />
                        FRANCE
                    </address>

                    <p>ou par e-mail en vous adressant directement à <a href="mailto:webmaster@lessonsharing.fr">webmaster@lessonsharing.fr</a>.</p>

                    <h2>Autres informations</h2>
                    {/*<h3>Contributeurs</h3>
                    <ul style={{marginTop:0}}>
                        <li>Monsieur Johan LINA &lt;<a href="mailto:johanlinapro@gmail.com">johanlinapro@gmail.com</a>&gt;, testeur, correcteur grammatical et orthographique</li>
                        <li>Monsieur Michael FERNANDEZ &lt;<a href="mailto:michaelferna39@yahoo.com">michaelferna39@yahoo.com</a>&gt;, testeur, traducteur officiel pour les langues anglaise et espagnole</li>
                        <li>Monsieur Sujithan KARUNAKARAN &lt;<a href="mailto:skr-@hotmail.fr">skr-@hotmail.fr</a>&gt;, testeur et chargé du business plan</li>
                    </ul>*/}

                    <h3>Propriété intellectuelle</h3>
                    <p>L’ensemble des éléments composant le site <a href={LESSONSHARING_URL}>www.lessonsharing.fr</a> tel que les textes, images, photographies, dessins, logos, marques, bases de données, vidéos, logiciels etc, sont la propriété exclusive de LessonSharing SARL ou du ou des tiers à qui ils appartiennent.</p>
                    <p>L’utilisateur s’interdit d’y porter une atteinte quelconque.</p>
                    <p>Toute reproduction, distribution, modification, adaptation, retransmission ou publication, même partielle, de ces différents éléments est strictement interdite sans l'accord exprès par écrit de LessonSharing SARL.</p>
                    <p>Cette représentation ou reproduction, par quelque procédé que ce soit, constitue une contrefaçon sanctionnée par les articles L.335-2 et suivants du Code de la propriété intellectuelle.</p>
                    <p>Le non-respect de cette interdiction constitue une contrefaçon pouvant engager la responsabilité civile et pénale du contrefacteur.</p>
                    <p>En outre, les propriétaires des contenus copiés pourraient intenter une action en justice à votre encontre.</p>

                    <h3>Hyperliens</h3>
                    <p>Les utilisateurs et les visiteurs peuvent mettre un hyperlien sans autorisation préalable, en direction de la page d’accueil du site internet <a href="https://www.lessonsharing.fr">www.lessonsharing.fr</a>.</p>
                    <h3>Informatiques et libertés</h3>
                    <p>Les données recueillies sur le site sont destinées à l’usage de LessonSharing SARL à des fins de gestion administrative et ne font l’objet d’aucune communication à des tiers.</p>
                    <p>Conformément à la loi informatique et libertés, l’utilisateur dispose d’un droit d’accès, de modification ou de suppression des données le concernant sur simple demande à l’adresse suivante: <a href="mailto:contact@lessonsharing.fr">contact@lessonsharing.fr</a>.</p>
                    <p>Conformément à la loi 78-17 du 6 janvier 1978 (modifiée par la loi 2004-801 du 6 août 2004 relative à la protection des personnes physiques à l'égard des traitements de données à caractère personnel) relative à l'informatique, aux fichiers et aux libertés, ce site a fait l'objet d'une déclaration sous le numéro 2036010 auprès de la Commission nationale de l'informatique et des libertés (<a href="https://www.cnil.fr/">www.cnil.fr</a>).</p>

                    <h3>Cookies <Illustration src="/cookies.svg" alt="Cookie"/></h3>
                    <p>Chez LessonSharing nous n'utilisons pas de cookies destinés à récolter des statistiques de vos visites ou de connaitre vos préférences vestimentaires... Nous préférons <b>les manger</b> !</p>
                    <p>C'est pourquoi nous ne vous <b>envoyons pas de cookie</b> lors de votre navigation sur notre site.<br />Hop, nous retournons à nos fourneaux :)</p>

                    <h3>Responsabilité</h3>
                    <p>Les informations contenues sur ce site sont aussi précises que possible, et le site est mis à jour à différentes périodes de l’année, mais peut toutefois contenir des inexactitudes ou des omissions.</p>
                    <p>LessonSharing SARL ne saurait garantir l’exactitude, la complétude et l’actualité des informations diffusées sur son site.</p>
                    <p>Les informations fournies le sont à titre indicatif.</p>
                    <p>L’utilisateur reconnaît utiliser les informations sur le site sous sa responsabilité exclusive.</p>

                    <h3>Loi applicable</h3>
                    <p>Le présent site est soumis à la loi française.</p>
                </div>
            </section>
            <SubPageFooter />
        </>
    );
}

