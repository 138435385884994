import './index.css'

export const ContribCard = ({name="No name", description="", picture="/nocardimg.jpeg"}) => (
    <div className="contribcard">
        <img src={picture} alt={name} />
        <p className="contribcard-name">{name}</p>
        {0 < description.length &&
            <p className="contribcard-description">{description}</p>
        }
    </div>
)
