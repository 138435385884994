import { Illustration, Logo, Button } from '../../atoms'
import './index.css'

export const SectionPresentation = () => {
    const LESSONSHARING_APP_URL = 'https://app.lessonsharing.fr/';

    return (
        <section className="section presentation" id={"Accueil"}>
            <div className="content">
                <div className="left">
                    <Logo />
                    <h1>LessonSharing</h1>
                    <h2>Formation et Hébergement</h2>
                    <Illustration className="mobile-only" src="/apps.svg" alt="application" />
                    <p>LessonSharing est une entreprise spécialisée dans les services informatiques.</p>
                    <p>Elle couvre les activités d'infogérance, d'hébergement, de developement logiciel, Web et mobile. <br />
                    Également investie dans l'enseignement à travers son service de formations professionnelles et formations en écoles supérieures.</p>
                    <p>LessonSharing propose aussi son application dédiée au stockage et au partage de documents !</p>
                    <br />
                    <div className="buttons">
                        <a href={LESSONSHARING_APP_URL} target='_blank' style={{textDecoration:'none'}}>
                             <Button className="dark secondary">Ouvrir LessonSharing</Button>
                        </a>
                        <a href="#Contact" rel='noreferrer' style={{textDecoration:'none'}}>
                            <Button className="dark">Nous contacter</Button>
                        </a>
                    </div>
                </div>
                <Illustration className="desktop-only" src="/apps.svg" alt="application" />
            </div>
        </section>
    )
}
