import "./index.css";

import { SectionPresentation, SectionServices, SectionApp, SectionForm, SectionFooter } from "../../organisms";

export const LandingPage = () => {
    return (
        <>
        <SectionPresentation/>
        <SectionServices/>
        <SectionApp/>
        <SectionForm/>
        <SectionFooter/>
        </>
    );
}
