import { Illustration } from '../../atoms'
import './index.css'

export const SectionServices = () => {
    return (
        <section className="section services">
            <div className="content">
                <h2>Nos services</h2>
                {/* Unit: Formation */}
                <div className="unit">
                    <div className="texts" id={"Formation"}>
                        <h3>Formation</h3>
                        <p>Impliquée depuis ses débuts dans l'enseignement, LessonSharing réunie une équipe de formateurs aux disciplines multiples dans le numérique.</p>
                        <p>À travers une approche plus "<b>technique</b>", les enseignants transmettent leurs compétences de manière ludique et pragmatique.</p>
                        <p>Formations inter et intra entreprises, formations continues et formations au sein d'écoles supérieures (BAC+1 à BAC+5) et facultés publiques, LessonSharing est reconnue par différents acteurs de la formation !</p>
                        <p>Les domaines de formation sont variés :</p>
                            <ul style={{marginTop:'0'}}>
                                <li>Développement d'application et Web</li>
                                <li>Conception logiciel / Génie logiciel</li>
                                <li>Cybersécurité, PenTest, Audit de sécurité</li>
                                <li>Ethical hacking, CEH</li>
                                <li>Secure Coding, OWASP, Écriture / Analyse de malware</li>
                                <li>Reverse Engineering, Sécurité IoT</li>
                                <li>Architectures Réseaux, Virtualisation, Cluster de services</li>
                                <li>Architectures Cloud, AWS, AZURE, Google Cloud Platform</li>
                                <li>Forensic, Analyse après une cyberattaque</li>
                                <li>EBIOS, ISO 2700x, SoC/SIEM, ATAWAD et BYOD</li>
                            </ul>
                        <p>N'hésitez pas à nous demander notre plaquette complète.</p>
                        <p>Nos experts vous accompagnent dans un apprentissage complet !</p>
                        <p>LessonSharing est enregistrée en tant qu'organisme de formation sous le numéro 11910920091.</p>
                    </div>
                    <Illustration src="/teach.svg" alt="enseignement"></Illustration>
                </div>

                {/* Unit: Infogérance */}
                <div className="unit">
                    <div className="texts" id={"Infogerance"}>
                        <h3>Infogérance</h3>
                        <p>Qu'il s'agisse de la gestion de vos communications VPN, de vos sauvergardes ou de vos services métiers, de la gestion de vos accès à distance (télétravail, serveurs, fichiers, etc.)... Les experts de LessonSharing mettent à disposition leurs expertises pour l'administration de votre parc informatique.</p>
                        <p>Nos clients nous font confiance sur une variété de service :</p>
                            <ul style={{marginTop:'0'}}>
                                <li>Solution de PRA (Plan de Reprise d'Activité) / PCA (Plan de Continuité d'Activité)</li>
                                <li>Tunnels VPN : client-à-site, site-à-site, site-à-cloud (Cloud Privé et dédié)</li>
                                <li>Gestion et Refonte de parc informatique</li>
                                <li>Gestion de solution d'hébergement existante</li>
                                <li>Solutions de sauvegardes (sur-site ou externalisées dans notre datacenter)</li>
                                <li>Sécurisation de votre S.I et de vos équipements</li>
                                <li>Solutions de chiffrement de vos données</li>
                            </ul>
                        <p>N'hésitez pas à nous demander la liste complète !</p>
                        <p>Une vraie DSI (Direction des Systèmes d'Information) externalisée ! Une équipe d'experts au complet pour vous accompagner dans vos projets et vous décharger de toute la gestion de votre informatique.</p>
                    </div>
                    <Illustration src="/infogerence.svg" alt="infogerence"></Illustration>
                </div>

                {/* Unit: Hébergement */}
                <div className="unit">
                    <div className="texts" id={"Hebergement"}>
                        <h3>Hébergement</h3>
                        <p>LessonSharing étant propriétaire de zones privées de datacenter en France, propose plusieurs types d'hébergements.</p>
                        <ul style={{marginTop:'0'}}>
                            <li>Conception de solutions d'hébergements</li>
                            <li>Hébergement Web (mutualisé et dédié), hébergement d'application</li>
                            <li>VPS, Serveur Dédié, Conteneur</li>
                            <li>Solution de stockage décentralisé</li>
                            <li>Sécurisation applicatif par différentes couches (Reverse Proxy, WAF, SSL/TLS, etc.)</li>
                        </ul>
                        <p>Nous vous accompagnons dans la conception et la mise en place d'un modèle d'hébergement adapté à votre infrastructure et environnements métiers (IaaS, PaaS, DBaaS, MaaS, SaaS).</p>
                        <p>LessonSharing met à disposition ses ressources en datacenter.</p>
                        <p>Une équipe d'experts vous sera dédiée pour vous accompagner dans vos projets et en assurer le suivi !</p>
                    </div>
                    <Illustration src="/hebergement.svg" alt="hebergement"></Illustration>
                </div>

                {/* Unit: Dévelopement */}
                {/*<div className="unit">
                    <div className="texts">
                        <h3>Développement</h3>
                        <p>De la conception, au design, à la mise en production, LessonSharing réalise vos sites Web et applications mobiles pour tous les écrans et toutes les plateformes !</p>
						<p>Nous sommes spécialisé dans le développement logiciel, applications Web, site Web (vitrine, e-commerce, communautaire, industriel, blog, etc.), nous concevons des <b>sites sur-mesure</b>.</p>
						<p>Notre équipe s'occupe d'intégrer une stratégie de référencement adaptée à votre contenu (appelée <q><b>SEO</b></q>) ainsi que de votre <q><b>Stratégie de Communications</b></q>, vos réseaux sociaux, vos campagnes d'emailing... LessonSharing est plus qu'une simple <q>Agence Web</q>, graçe à notre infrastructure complète et sécurisée, nous sommes en mesure de vous apporter une multitude de service adaptés à vos projets !</p>
						<p>
							<ul style={{marginTop:'0'}}>
								<li>Conseil et Stratégie en Communication</li>
								<li>Photographe</li>
								<li>Web Designer (UX / UI)</li>
								<li>Dévelopement <b>sécurisée</b> (règles de sécuritée au sein du code source)</li>
								<li>Création de logo, conception de charte graphique</li>
								<li>Application Mobile</li>
								<li>Dépôt de <b>marque</b> internationale</li>
								<li>Emailing <b>moderne</b></li>
								<li>Hébergement sécurisé</li>
								<li>Rédaction de CGV, CGU, Mentions légales, contrat de licence (toutes les rédactions sont réalisées par un avocat dédié</li>
							</ul>
							Des solutions complète et mise à l'épreuve pour des grands comptes, accessibles et disponibles pour vos projets ! N'hésitez pas à nous contacter pour nous présenter votre projet et nous vous fournirons une <b>étude gratuite</b>.
						</p>
						<p>Si vous avez déjà un site Web en place, nous pouvons réaliser un <b>audit gratuit</b> et vous indiquer si nous pouvons faire mieux, ou non !</p>
                    </div>
                    <Illustration src="/dev.svg" alt="developpement"></Illustration>
                </div>*/}
            </div>
        </section >
    )
}
