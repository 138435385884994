import { Link } from  'react-router-dom'
import { Button, Logo, SvgArrowGoback } from '../../atoms'
import './index.css'

export const SubPageHeader = ({title}) => {
    return (
        <header className="subpage-header">
            <Link to="/">
                <Button className="secondary">
                    <SvgArrowGoback />
                </Button>
            </Link>
            <h1>{title}</h1>
            <Logo />
        </header>
    )
}
