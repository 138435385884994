export const SvgArrowGoback = (props) => {
    const stroke = "#F5F5F6";

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={47}
            height={77}
            viewBox="-0.5 -0.5 47 77"
            {...props}
        >
        <g fill="none" pointerEvents="stroke">
        <path d="M3 38 43 3" />
        <path
            d="M3 38c10.12-9.48 20.01-16.73 40-35M3 38C16.25 27.13 28.37 16.71 43 3"
            stroke={stroke}
            strokeWidth={10}
            strokeLinejoin="round"
            strokeLinecap="round"
            strokeMiterlimit={10}
        />
        <path d="m3 38 40 35" />
        <path
            d="M3 38c9.22 10.06 17.93 15.28 40 35M3 38c15.32 14.11 31.9 27.91 40 35"
            stroke={stroke}
            strokeWidth={10}
            strokeLinejoin="round"
            strokeLinecap="round"
            strokeMiterlimit={10}
        />
        </g>
        </svg>
    );
}

export default SvgArrowGoback;
