import { Switch, Route } from 'react-router-dom';
import { LandingPage, ContributorsPage, LegalNoticesPage, NotFound } from './components/pages';
import './App.css';

// AppRouter.
export default function AppRouter() {
    return (
        <Switch>
            <Route exact path='/'><Main /></Route>
            <Route exact path='/legal-notices'><LegalNotices /></Route>
            <Route exact path='/contributors'><Contributors /></Route>
            <Route path='*'><NotFound /></Route>
        </Switch>
    );
}

function Main() {
    return (<LandingPage></LandingPage>);
}

function LegalNotices() {
    return (<LegalNoticesPage></LegalNoticesPage>);
}

function Contributors() {
    return (<ContributorsPage></ContributorsPage>);
}

